const local = {
  domain: "localhost",
  domain_secure: false,
  ga_analytics: '',
  login_url: 'http://localhost:3001',
  s3: {
    BUCKET: "files.dashboard.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_5VsTpB8bg",
    APP_CLIENT_ID: "fdgu6arr80mql74bc1pciq7ga",
    IDENTITY_POOL_ID: "us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d"
  },
  terms: 'http://neokodelabs.com/terms',
  policy: 'http://neokodelabs.com/policy'
};

const dev = {
  domain: "neokodelabs.com",
  domain_secure: true,
  ga_analytics: '',
  login_url: 'https://admin.neokodelabs.com',
  s3: {
    BUCKET: "files.dashboard.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_5VsTpB8bg",
    APP_CLIENT_ID: "fdgu6arr80mql74bc1pciq7ga",
    IDENTITY_POOL_ID: "us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d"
  },
  terms: 'http://neokodelabs.com/terms',
  policy: 'http://neokodelabs.com/policy'
};

const prod = {
  domain: "neokode.cl",
  domain_secure: true,
  ga_analytics: 'UA-',
  login_url: 'https://admin.neokode.cl',
  s3: {
    BUCKET: "files.dashboard.neokode.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokode.cl"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_eEusLaI2w",
    APP_CLIENT_ID: "2ek3t58ujs5e79sthtl4gm1rm2",
    IDENTITY_POOL_ID: "us-east-1:937bc5bd-616c-4be1-b522-cc325214693c"
  },
  terms: 'https://www.neokode.cl/terms',
  policy: 'https://www.neokode.cl/policy'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

import React from "react";
import { Route } from "react-router-dom";
import RedirectLogin from './RedirectLogin';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
        ? <C {...props} {...cProps} />
        : <RedirectLogin
            to={`/login?redirect=${window.location.origin}${props.location.pathname}${props.location
              .search}`}
          />
    }
  />;

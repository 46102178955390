import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import unregisterServiceWorker from './registerServiceWorker';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// GA
import { createBrowserHistory } from 'history';

export default createBrowserHistory({});

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);
//registerServiceWorker();
unregisterServiceWorker();

import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Navbar, Nav, Alert, Container } from "react-bootstrap";
import Routes from "./Routes";
import { authUser, getUserAttributes } from "./libs/awsLib";

import config from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './menu.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      showAlertError: false,
      alertMessage: '',
      profile: null,
      fullname: '',
      expanded: false
    };
  }

  async componentDidMount() {
    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      //this.handleAlertError('Usuario no autenticado');
    }
    this.setState({ isAuthenticating: false });
  }

  async componentDidUpdate() {
    const user = await getUserAttributes();
    if (user.profile !== this.state.profile) {
      this.setState({ profile: user.profile });
    }
  }
  
  userHasAuthenticated = (authenticated, fullname) => {
    this.setState({ isAuthenticated: authenticated, fullname: fullname });
  }

  onToggle = (event) => {
    this.setState({ expanded: !this.state.expanded });
  }

  onSelect = (event) => {
    this.setState({ expanded: false });
  }

  onSelectMenu = (event) => {
    this.setState({ expanded: false, openFleetMenu: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message, showAlertError: true });
  }

  handleDismissError = () => {
    this.setState({ showAlertError: false });
  }

  renderPrivate(childProps) {
    return (
      <React.Fragment>
        <div className="header">
          <Container>
            <Navbar onToggle={this.onToggle} onSelect={this.onSelect} expanded={this.state.expanded} expand="lg">
              <Navbar.Brand>
                <Link to="/">
                  Dashboard App
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="mr-auto">
                  {/*<Nav.Item>
                    <Link to="/link1" className="nav-link">Link 1</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to="/link2" className="nav-link">Link 2</Link>
                  </Nav.Item>*/}
                </Nav>
                <Nav className="justify-content-end">
                  <Nav.Item>
                    <Nav.Link href={config.login_url}>
                      Admin App
                    </Nav.Link>
                  </Nav.Item>
                  <Navbar.Brand className="image">
                    <img src="https://neokode.cl/static/media/black-logo.523b9830.png" alt="logo" />
                  </Navbar.Brand>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Routes childProps={childProps} />
      </React.Fragment>
    );
  }

  renderPublic(childProps) {
    return (
      <div className="row">
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Routes childProps={childProps} />
      </div>
    );
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App">
        {
          this.state.isAuthenticated ? this.renderPrivate(childProps) : this.renderPublic(childProps)
        }
        <footer className="navbar-default">
          <div className="nav-footer">
            <a href={config.terms} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
            <a href={config.policy} target="_blank" rel="noopener noreferrer">Política de privacidad</a>
            <center className="copyright">
              © App desarrollada por <a href="http://www.neokode.cl" target="_blank" rel="noopener noreferrer">Neokode</a>
            </center>
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(App);

import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import config from '../config';

export default class RedirectLogin extends Component {
  handleClick = (evt) => {
    evt.preventDefault();
    console.log('to: ' + this.props.to);
    window.location = config.login_url + this.props.to;
  }
  render() {
    return (
      <Modal show={true}>
        <Modal.Body>
          <p>
            No está autorizado para ingresar, por favor ingrese nuevamente con su usuario y contraseña
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClick}>Iniciar sesión</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

import Body from "./components/contenido/Body";
//import Component1 from "./containers/Component1";
//import Component2 from "./containers/Component2";

import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Body} props={childProps} />
    {/*
    <AuthenticatedRoute path="/link1" exact component={Component1} props={childProps} />
    <AuthenticatedRoute path="/link2" exact component={Component2} props={childProps} />
    */}
    <Route component={NotFound} />
  </Switch>;
